import React, { useEffect } from "react";

const GoogleAd = () => {
  //   useEffect(() => {
  //     (window.adsbygoogle = window.adsbygoogle || []).push({});
  //   }, []);

  return (
    <>
      <div className="ad-box">
        <div className="text-center pb-3 pt-0 pt-3 mb-3 ml-2 mr-2">
        Elevate your travel experience with <a href="/pro"> departureboard.io Pro</a>.<br/><br/> View platforms early with our predictive platform service, view detailed calling points and operator information, and gain exclusive access to the Onboard Hub and additional features. <br/><br/>Free for 14 days, then just £1.99/month. Cancel anytime.
        </div>
      </div>
    </>
  );
};

export default GoogleAd;
